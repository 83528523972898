import Vue from 'vue'
import App from './App.vue'
import router from '@/router'
import store from '@/store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'font-awesome/css/font-awesome.css';

import {getRequest} from "@/utils/api";
import {postRequest} from "@/utils/api";
import {putRequest} from "@/utils/api";
import {deleteRequest} from "@/utils/api";
import {initMenu} from "@/utils/menus";
import {initSite} from "@/utils/sites";
import {preventReClick} from "@/utils/plugins";

Vue.use(ElementUI);

Vue.config.productionTip = false
Vue.prototype.getRequest = getRequest;
Vue.prototype.postRequest = postRequest;
Vue.prototype.putRequest = putRequest;
Vue.prototype.deleteRequest = deleteRequest;

router.beforeEach(async (to, from, next) => {
  // document.title = "嘉讯茂移动办公平台"
  if(window.sessionStorage.getItem("tokenStr")) {
    await initSite(store);
    initMenu(router, store);
    console.log(store.state)
    if(!window.sessionStorage.getItem('user')) {
      //判断用户信息是否存在
       getRequest('/user/info').then((resp) => {
        console.log(resp)
        if(resp) {
          //存入用户信息
          window.sessionStorage.setItem('user', JSON.stringify(resp.data));

        }
        next();
      })
    }
  console.log(to, from, next, router)
    next();
  } else {
    if(to.path != '/') {
      console.log("输入目标地址跳转")
      next('/?redirect=' + to.path)
    } else {
      next();
    }
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
