import {getRequest} from "@/utils/api";

export const initSite = (store) => {
    return new Promise((a)=> {
        if(store.state.sites && store.state.sites.length > 0) {
            a(true)
            return;
        }
        getRequest('/common-query/mobile/site').then(data => {
            if (data) {
                //将数据存入Vuex
                store.commit('initSites', data)
                a(true)
            }
        })
    })
}
