import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from "@/views/Login";
import Home from "@/views/Home";

Vue.use(VueRouter)

// 解决报错
const originalPush = VueRouter.prototype.push
const originalReplace = VueRouter.prototype.replace
// push
VueRouter.prototype.push = function push (location, onResolve, onReject) {
  // if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}
// replace
VueRouter.prototype.replace = function replace (location, onResolve, onReject) {
  // if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
  // console.log("location ",location);
  // console.log("onResolve ",onResolve, "onReject ",onReject);
  return originalReplace.call(this, location).catch(err => {
      console.log("replace err ",err);
      originalReplace.call(this, location);
  })
}

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
    hide: true
  }
]

const router = new VueRouter({
  routes
})

export default router
