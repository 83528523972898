import {getRequest} from "@/utils/api";

export const initMenu = (router, store) => {
    return new Promise((a)=>{
        if(store.state.routes && store.state.routes.length > 0) {
            a(true)
            return;
        }

        getRequest('/system/cfg/menu').then(data => {
            if(data) {
                //格式化Router
                let fmtRoutes = formatRoutes(data);
                //添加到router
                console.log(fmtRoutes)
                router.addRoutes(fmtRoutes);
                //将数据存入Vuex
                store.commit('initRoutes', fmtRoutes)
                a(true)
            }
        })
    })

}

export const formatRoutes = (routes) => {
    let fmtRoutes = [];
    routes.forEach(router => {
        let {
            path,
            component,
            name,
            iconCls,
            children,
        } = router;
        if(children && children instanceof Array) {
            //递归
            children = formatRoutes(children);
        }
        let fmRouter = {
            path: path,
            name: name,
            iconCls: iconCls,
            children: children,
            component(resolve) {
                if(component.startsWith('Home')) {
                    require(['../views/' + component + '.vue'], resolve)
                } else if(component.startsWith('Sys')) {
                    require(['../views/sys/' + component + '.vue'], resolve)
                } else if(component.startsWith('Master')) {
                    require(['../views/master/' + component + '.vue'], resolve)
                } else if(component.startsWith('Authorize')) {
                    require(['../views/authorize/' + component + '.vue'], resolve)
                }
            }
        }
        fmtRoutes.push(fmRouter)
    });
    return fmtRoutes;
}
