<template>
  <div class="login">
    <div class="login-bg"></div>
    <el-form :rules="loginFormRules"
             v-loading="loading"
             element-loading-text="正在登录..."
             element-loading-spinner="el-icon-loading"
             element-loading-background="rgba(0, 0, 0, 0.8)"
             ref="loginForm"
             :model="loginForm"
             class="login-form">
      <h3 class="login-title">系统登录</h3>
      <div class="login-container">
        <el-form-item prop="username">
          <el-input type="text" placeholder="请输入登录名" auto-complete="false"
                    v-model="loginForm.username">
            <img slot="prefix" class="input-icon" :src="userImg" />
          </el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input type="password" placeholder="请输入密码" auto-complete="false" v-model="loginForm.password">
            <img slot="prefix" class="input-icon" :src="passwordImg" />
          </el-input>
        </el-form-item>
        <el-form-item prop="code">
          <el-input type="text" placeholder="请输入验证码" auto-complete="false" v-model="loginForm.code">
<!--                    style="width: 250px; margin-right: 5px">-->
            <img slot="prefix" class="input-icon" :src="codeImg" />
            <img slot="suffix" :src="captchaUrl" @click="updateCaptcha" style="height: 85%; border-radius: 2px;">
          </el-input>
<!--          <img :src="captchaUrl" @click="updateCaptcha">-->
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="login">登录</el-button>
          <el-button type="info" @click="resetLoginForm">重置</el-button>
          <el-button @click="send">发送</el-button>
        </el-form-item>
      </div>
    </el-form>
  </div>

</template>

<script>
import { createSocket } from '@/utils/custom-socket'
export default {
  name: "Login",
  mounted() {
    // this.socket = createSocket('wss://fc.zfire.top', {
    //   path: '/socket/socket.io',
    //   query: {
    //     id: '12345',
    //     flag: 'salesRemind'
    //   }
    // })
    // this.$nextTick(() => {
    //   this.socket.on('connect', () => {
    //     console.log('socket connect')
    //   })
    //   this.socket.on('reconnect', (attemptNumber) => {
    //     console.log('socket reconnect ' + attemptNumber)
    //   })
    //   this.socket.on('reconnecting', (attemptNumber) => {
    //     console.log('socket reconnecting ' + attemptNumber)
    //   })
    //   this.socket.on('reconnect_error', (error) => {
    //     console.log('socket reconnect_error ' + error)
    //   })
    //   this.socket.on('reconnect_failed', () => {
    //     console.log('socket reconnect_failed ')
    //   })
    //   this.socket.on('ping', () => {
    //     console.log('socket ping ')
    //   })
    //   this.socket.on('pong', (latency) => {
    //     console.log('socket pong ' + latency)
    //   })
    //   this.socket.on('message', (data) => {
    //     console.log('server message: ' + data)
    //   })
    // })
  },
  data() {
    return {
      userImg: require('../assets/login/user.png'),
      passwordImg: require('../assets/login/password.png'),
      codeImg: require('../assets/login/code.png'),
      captchaUrl: '/api/code?time=' + new Date(),
      loginForm: {
        username: 'admin',
        password: '123',
        code: ''
      },
      loading: false,
      //表单验证规则
      loginFormRules: {
        username: [
          {
            required: true,
            message: '请输入登录名',
            trigger: 'blur'
          },
          {
            min: 3,
            max: 10,
            message: '登录名长度在 3 到 10 个字符',
            trigger: 'blur'
          }
        ],
        password: [
          {
            required: true,
            message: '请输入密码',
            trigger: 'blur'
          },
          {
            min: 3,
            max: 15,
            message: '密码长度在 3 到 15 个字符',
            trigger: 'blur'
          }
        ],
        code: [
          {
            required: true,
            message: '请输入验证码',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  methods: {
    send() {
      console.log(this.socket)
      this.socket.emit('messageevent', '发送消息!')
      // this.$IO_getSocket('socket').emit('messageevent', '发送消息!')
    },
    updateCaptcha() {
      this.captchaUrl = '/api/code?time=' + new Date();
    },
    //添加表单重置方法
    resetLoginForm() {
      //this=>当前组件对象，其中的属性$refs包含了设置的表单ref
      //   console.log(this)
      this.$refs.loginForm.resetFields()
    },
    login() {
      //点击登录的时候先调用validate方法验证表单内容是否有误
      this.$refs.loginForm.validate((valid) => {
        //如果valid参数为true则验证通过
        if (valid) {
          this.loading = true;
          this.postRequest('/login', this.loginForm).then((resp)=> {
            if(resp) {
              this.loading = false;
              //存储用户token
              const tokenStr = resp.data.tokenHead + resp.data.token;
              window.sessionStorage.setItem("tokenStr", tokenStr);
              //页面跳转
              let path = this.$route.query.redirect;
              console.log("在登录页面的path ", path);
              this.$router.replace((path == '/' || path == undefined) ? '/home' : path);
            }
          })
          this.loading = false;
        } else {
          this.$message.error("请输入所有字段！")
          return false;
        }
      })
    }
  }
}
</script>

<style>
.login {
  height: 100%;
}
.login-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-width: 1000px;
  z-index: -10;
  zoom: 1;
  background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  background: #fff url("../assets/login/bg.png") no-repeat center 0;
  background-size: 100% 100%;
}
.login-form {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  background: rgb(252 252 252 / 30%);
  border-radius: 5px;
  min-width: 375px;
}
.login-container {
  padding: 20px 30px;
}
.input-icon {
  padding: 0 10px;
}
.login .el-input--prefix .el-input__inner {
  padding-left: 50px;
}
/*.loginContainer {*/
/*  border-radius: 15px;*/
/*  background-clip: padding-box;*/
/*  margin: 180px auto;*/
/*  width: 350px;*/
/*  padding: 15px 35px 15px 35px;*/
/*  background: #fff;*/
/*  border: 1px solid #eaeaea;*/
/*  box-shadow: 0 0 15px #cac6c6;*/
/*}*/
.login-title {
  margin: 20px auto 20px auto;
  text-align: center;
}
.el-form-item__content {
  display: flex;
  align-items: center;
}
.el-form-item:last-child>.el-form-item__content {
 justify-content: center;
}
</style>
